import React, { useState, useEffect, useCallback, memo } from "react";
import { urls } from "../../config/constants";
import { GetDate, todayDate } from "../../services/DateAndTime";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import { commonRoutes, HomeARRoutes, SuzukiARRoutes } from "../../config/RouteConfig";
import AOSRefresh_image2svg from "../../Models/AOSRefresh_image2svg";

const Footer = () => {
	AOSRefresh_image2svg();
	return (
		<footer className='footer light-grey-bg' data-aos='fade-up'>
			<div className='footer-wrapper'>
				<div className='container-fluid'>
					<div className='row'>
						<div className='col-lg-3 col-md-3 col-sm-6'>
							<div className='footer-widget'>
								<h4 className='text-white text-uppercase'>ما هو الجديد</h4>
								<ul className='mb-0 list-unstyled'>
									<li>
										<a href='https://moosagroup.com/suzuki/carmodel/swift' target='_blank'>
											Swift
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className='col-lg-3 col-md-3 col-sm-6'>
							<div className='footer-widget'>
								<h4 className='text-white text-uppercase'>روابط سريعة</h4>
								<ul className='mb-0 list-unstyled'>
									<li>
										<Link to={`/${SuzukiARRoutes.test_drive}`}>طلب اختبار قيادة </Link>
									</li>
									<li>
										<Link to={`/${SuzukiARRoutes.requestQuoteRoute}`}>طلب عرض أسعار</Link>
									</li>
									{/* <li><Link to={`/${SuzukiARRoutes.ournetworkRoute}`}>OUR NETWORK</Link></li> */}
								</ul>
							</div>
						</div>
						<div className='col-lg-3 col-md-3 col-sm-6'>
							<div className='footer-widget'>
								<h4 className='text-white text-uppercase'>
									<a href='#'>اتصل بنا</a>
								</h4>
								<ul className='mb-0 list-unstyled'>
									<li>
										<a href='tel:80076200' target='_blank'>
											80076200
										</a>
									</li>
									<li>
										<a href='mailto:moosaconnect@moosagroup.com' target='_blank'>
											moosaconnect@moosagroup.com
										</a>
									</li>
									<li>
										<a href='#' target='_blank'>
											P.O Box 4. Postal Code 112. Muscat
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className='col-lg-2 col-md-3 col-sm-6'>
							<div className='footer-widget'>
								<h4 className='text-white text-uppercase'>تابعنا</h4>
								<ul className='social-icons list-unstyled d-flex align-content-center justify-content-end'>
									<li className='text-uppercase text-white'>GMC</li>
									<li>
										<a href='https://www.facebook.com/gmc.oman' target='_blank'>
											<img className='in-svg' width='15' height='15' src={`${urls.frontendUrl}/images/facebook.svg`} alt='Facebook' />
										</a>
									</li>
									<li>
										<a href='https://www.instagram.com/gmc_oman/' target='_blank'>
											<img className='in-svg' width='15' height='15' src={`${urls.frontendUrl}/images/instagram.svg`} alt='Instagram' />
										</a>
									</li>
								</ul>
								<ul className='social-icons list-unstyled d-flex align-content-center justify-content-end'>
									<li className='text-uppercase text-white'>SUZUKI</li>
									<li>
										<a href='https://www.facebook.com/suzuki.oman/' target='_blank'>
											<img className='in-svg' width='15' height='15' src={`${urls.frontendUrl}/images/facebook.svg`} alt='Facebook' />
										</a>
									</li>
									<li>
										<a href='https://www.instagram.com/suzukioman/' target='_blank'>
											<img className='in-svg' width='15' height='15' src={`${urls.frontendUrl}/images/instagram.svg`} alt='Instagram' />
										</a>
									</li>
								</ul>
								
							</div>
						</div>

						<div className='col-md-12'>
							<div className='footer__logo text-center mt-5'>
								<Link to={`/${HomeARRoutes.home}`} title='Moosa Abdul Rahman Hassan & Company'>
									<img className='lazy-load' src={`${urls.frontendUrl}/images/footer-logo.png`} width='645' height='68' alt='Moosa Abdul Rahman Hassan & Company' />
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='footer__copyright'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-12'>
							<p className='copyright__text text-center mb-0 text-uppercase mb-0'>
								© <script>document.write(new Date().getFullYear())</script>2024 MOOSA ABDUL RAHMAN HASSAN &amp; CO. LLC | Developed by Tech Galaxy
							</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
