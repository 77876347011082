import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { urls } from "../../config/constants";
import { commonRoutes, HomeARRoutes, HomeRoutes, SuzukiARRoutes, SuzukiRoutes } from "../../config/RouteConfig";
import { image2svg } from "../../utilsfunctions/Svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { callApi } from "../../services/ApiService";
import { SuzukiApi } from "../../config/api";
import { defaultHeader, toastError, toastSuccess } from "../../services/CommonFunction";
import GetSuzukiCar from "../../Models/GetSuzukiCar";
import classNames from "classnames";

const Header = () => {
	const [carList, setCarList] = GetSuzukiCar();

	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const handleShowNavbar = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

	const handleShowSubNavbar = () => {
		setIsSubMenuOpen(!isSubMenuOpen);
	};

	const [isSubMenuOpen1, setIsSubMenuOpen1] = useState(false);

	const handleShowSubNavbar1 = () => {
		setIsSubMenuOpen1(!isSubMenuOpen1);
	};

	const [isSubMenuOpen2, setIsSubMenuOpen2] = useState(false);

	const handleShowSubNavbar2 = () => {
		setIsSubMenuOpen2(!isSubMenuOpen2);
	};

	const [isSubMenuOpen3, setIsSubMenuOpen3] = useState(false);

	const handleShowSubNavbar3 = () => {
		setIsSubMenuOpen3(!isSubMenuOpen3);
	};

	const [isSubMenuOpen4, setIsSubMenuOpen4] = useState(false);

	const handleShowSubNavbar4 = () => {
		setIsSubMenuOpen4(!isSubMenuOpen4);
	};

	useEffect(() => {
		image2svg();
		AOS.init();
		AOS.refresh();
	}, []);

	return (
		<header className='header'>
			<div className='header__top'>
				<div className='container-fluid'>
					<div className='row align-items-center'>
						<div className='col-md-4'>
							<div className='logo'>
								<Link to={`/${SuzukiARRoutes.home}`}>
									<picture>
										<img src={`${urls.frontendUrl}/images/suzuki-logo-white.png`} width='131' height='25' alt='Moosa Abdul Rahman Hassan & Company' />
									</picture>
								</Link>
							</div>
						</div>
						<div className='col-md-8'>
							<div className='header__top--links d-flex align-items-end justify-content-end flex-column'>
								<ul className='header__top--action list-unstyled d-flex align-items-end'>
									<li>
										<a href='tel:80076200'>
											{" "}
											<span>CALL: 80076200</span>
										</a>
									</li>
									<li>
										<Link to={`/${SuzukiRoutes.home}`}>ENG</Link>
										{/* <a href="#">ENG</a> */}
									</li>
								</ul>
								<ul className='header__top--logos mb-0 list-unstyled d-flex align-items-end'>
									<li className='mr-0'>
										<Link to={`/${HomeARRoutes.home}`}>
											<img src={`${urls.frontendUrl}/images/logo.png`} width='75' height='20' alt='GSM' />
										</Link>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='header__main'>
				<div className='container-fluid'>
					<div className='mobile__menu'>
						<div className='mobile__line'></div>
						<div className='mobile__line'></div>
						<div className='mobile__line'></div>
					</div>
					<nav className='navbar navbar-expand-lg p-0'>
						<ul className='navbar-nav justify-content-center'>
							<li className='nav-item has-child'>
								<a className='nav-link' href='#'>
									طرازات
								</a>
								<span className='menu-arrow' onClick={handleShowSubNavbar3}>
									<img className='in-svg' src={`${urls.frontendUrl}/images/down-arrow.svg`} alt='Arrow' />
								</span>
								<ul className={`nav-sub-menu list-unstyled has-grand-children  ${isSubMenuOpen3 && "d-block"}`}>
									{/* Dyamic */}
									<li className='sub-menu-item has-child'>
										<a className='nav-link' href='#'>
											سيارة سيدان
											<span className='menu-arrow'>
												<img className='in-svg' src={`${urls.frontendUrl}/images/right-arrow.svg`} alt='Arrow' />
											</span>
										</a>
										<ul className={`nav-sub-sub-menu list-unstyled`}>
											{carList.map(
												(v, i) =>
													v?.category == "Sedan" && (
														<li className='sub-menu-item' key={"sed" + i}>
															<Link to={`/${SuzukiARRoutes.carmodelRoute}/${v?.alias}`} key={i}>
																{v.title_arr}
															</Link>
														</li>
													)
											)}
										</ul>
									</li>
									<li className='sub-menu-item has-child'>
										<a className='nav-link' href='#'>
											سيارة دفع رباعي
											<span className='menu-arrow'>
												<img className='in-svg' src={`${urls.frontendUrl}/images/right-arrow.svg`} alt='Arrow' />
											</span>
										</a>

										<ul className={`nav-sub-sub-menu list-unstyled`}>
											{carList.map(
												(v, i) =>
													v?.category == "SUV" && (
														<li className='sub-menu-item' key={"sed" + i}>
															<Link to={`/${SuzukiARRoutes.carmodelRoute}/${v?.alias}`} key={i}>
																{v.title_arr}
															</Link>
														</li>
													)
											)}
										</ul>
									</li>
									<li className='sub-menu-item has-child'>
										<a className='nav-link' href='#'>
											مركبة تجارية
											<span className='menu-arrow'>
												<img className='in-svg' src={`${urls.frontendUrl}/images/right-arrow.svg`} alt='Arrow' />
											</span>
										</a>

										<ul className={`nav-sub-sub-menu list-unstyled`}>
											{carList.map(
												(v, i) =>
													v?.category == "Commercial" && (
														<li className='sub-menu-item' key={"sed" + i}>
															<Link to={`/${SuzukiARRoutes.carmodelRoute}/${v?.alias}`} key={i}>
																{v.title_arr}
															</Link>
														</li>
													)
											)}
										</ul>
									</li>
								</ul>
							</li>
							<li className='nav-item has-child'>
								<a className='nav-link' href='#'>
									ما بعد البيع
								</a>
								<span className='menu-arrow' onClick={handleShowSubNavbar2}>
									<img className='in-svg' src={`${urls.frontendUrl}/images/down-arrow.svg`} alt='Arrow' />
								</span>
								<ul className={`nav-sub-menu list-unstyled  ${isSubMenuOpen2 && "d-block"}`}>
									<li className='sub-menu-item'>
										<Link to={`/${SuzukiARRoutes.book_your_service}`}>احجز خدمتك</Link>
									</li>
									<li className='sub-menu-item'>
										<Link to={`/${SuzukiARRoutes.menuprice}`}>تسعير القائمة</Link>
									</li>
									<li className='sub-menu-item'>
										<Link to={`/${SuzukiARRoutes.maintenance}`}>الصيانة</Link>
									</li>
									<li className='sub-menu-item'>
										<Link to={`/${SuzukiARRoutes.body_shop}`}>ورشة إصلاح جسم السيارة</Link>
									</li>
									<li className='sub-menu-item'>
										<Link to={`/${SuzukiARRoutes.partEnquiryRoute}`}>الاستعلام عن قطع غيار</Link>
									</li>
									{/* <li className="sub-menu-item">
                                    <Link to={`/${SuzukiARRoutes.book_your_service}`}>Service Enquiry</Link>
                                </li>*/}
								</ul>
							</li>

							<li className='nav-item'>
								<a className='nav-link' href='#'>
									العروض
								</a>
								<span className='menu-arrow' onClick={handleShowSubNavbar1}>
									<img className='in-svg' src={`${urls.frontendUrl}/images/down-arrow.svg`} alt='Arrow' />
								</span>
								<ul className={`nav-sub-menu list-unstyled  ${isSubMenuOpen1 && "d-block"}`}>
									<li className='sub-menu-item'>
										<Link to={`/${SuzukiARRoutes.suzukicurrentoffers}`}>العرض الحالي </Link>
									</li>
									{/* <li className='sub-menu-item'>
										<Link to={`/${SuzukiARRoutes.bmeppRoute}`}>bm epp</Link>
									</li> */}
								</ul>
							</li>

							<li className={classNames("nav-item has-child", { active: [`/${SuzukiARRoutes.enquiryRoute}`, `/${SuzukiARRoutes.ournetworkRoute}`].includes(window.location.pathname) })}>
								<a className='nav-link' href='#'>
									اتصل بنا
								</a>
								<span className='menu-arrow' onClick={handleShowSubNavbar4}>
									<img className='in-svg' src={`${urls.frontendUrl}/images/down-arrow.svg`} alt='Arrow' />
								</span>
								<ul className={`nav-sub-menu list-unstyled  ${isSubMenuOpen4 && "d-block"}`}>
									<li className='sub-menu-item'>
										<Link to={`/${SuzukiARRoutes.ournetworkRoute}`}>شبكتنا</Link>
									</li>
									<li className='sub-menu-item'>
										<Link to={`/${SuzukiARRoutes.enquiryRoute}`}>استفسارات </Link>
									</li>
								</ul>
							</li>
							<li className='nav-item'>
								<a className='nav-link' href='#'>
									سوزوكي العالمية
								</a>
								<span className='menu-arrow' onClick={handleShowSubNavbar}>
									<img className='in-svg' src={`${urls.frontendUrl}/images/down-arrow.svg`} alt='Arrow' />
								</span>
								<ul className={`nav-sub-menu list-unstyled  ${isSubMenuOpen && "d-block"}`}>
									<li className='sub-menu-item'>
										<a href='https://www.globalsuzuki.com/' target='_blank'>
											سوزوكي العالمية
										</a>
									</li>
									<li className='sub-menu-item'>
										<a href='https://www.globalsuzuki.com/automobile/' target='_blank'>
											سوزوكي للسيارات
										</a>
									</li>
									<li className='sub-menu-item'>
										<a href='https://www.globalsuzuki.com/corporate/' target='_blank'>
											أخبار سوزوكي
										</a>
									</li>
								</ul>
							</li>
							{/* <li className='nav-item'>
								<Link to={`/${SuzukiARRoutes.moosaconnect}`} className='nav-link'>
									Moosa Connect
								</Link>
							</li> */}
						</ul>
						<div className='mobile__items p-2 mt-4 bg-white'>
							<ul className='header__top--logos list-unstyled d-flex align-content-center'>
								<li className='d-flex align-content-center justify-content-center'>
									<Link to={`/${HomeARRoutes.home}`}>
										<img src={`${urls.frontendUrl}/images/logo.png`} width='75' height='20' alt='GSM' />
									</Link>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</div>

			<div className='side-menu' style={{ marginTop: "-100px" }}>
				<Link to={`/${SuzukiARRoutes.ournetworkRoute}`} className='side-menu-item'>
					<span>شبكتنا</span>
					<img className='in-svg' src={`${urls.frontendUrl}/images/location.svg`} alt='Side Menu Icon' />
				</Link>
				<Link to={`/${SuzukiARRoutes.downloadBrochures}`} className='side-menu-item'>
					<span>تحميل الكتيب</span>
					<img className='in-svg' src={`${urls.frontendUrl}/images/download.svg`} alt='Side Menu Icon' />
				</Link>
				<Link to={`/${SuzukiARRoutes.enquiryRoute}`} className='side-menu-item'>
					<span>كتاب اختبار القيادة</span>
					<img className='in-svg' src={`${urls.frontendUrl}/images/car.svg`} alt='Side Menu Icon' />
				</Link>
				<Link to={`/${SuzukiARRoutes.requestQuoteRoute}`} className='side-menu-item'>
					<span>طلب عرض أسعار</span>
					<img className='in-svg' src={`${urls.frontendUrl}/images/quote.svg`} alt='Side Menu Icon' />
				</Link>
			</div>
			<div className='whatsApp__fixed'>
				<a href='https://wa.me/+96894760010' target='_blank'>
					<img className='in-svg' src={`${urls.frontendUrl}/images/whatsapp-icon.svg`} alt='Whatsapp' />
				</a>
			</div>
		</header>
	);
};

export default React.memo(Header);

// const fetchCarsOfSuzuki = useCallback(async () =>{
//     await callApi(SuzukiApi.getCarsOfSuzuki.method,SuzukiApi.getCarsOfSuzuki.url,null,null,defaultHeader()).
//     then((res)=>{
//         const payload = res?.data?.data;
//         if(Array.isArray(payload) && payload.length>0){
//             setCarList(payload);
//         }
//     }).catch((err)=>{
//         console.log(err)
//         toastError(err?.response?.data?.message);
//     })
// },[]);
